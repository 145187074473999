import * as React from "react"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import TrainingWebinarSec from "../components/training-webinars/navigator-content"
import Hero from "../components/training-webinars/navigator-hero"

const SoftwareAccess = () => (
  <div className="contactPage softwareAccess trainingWebinars">
    <Layout>
      <Seo title="Software Access" />
      <div className="contactSecWrapper">
        <Hero />
        <TrainingWebinarSec />
      </div>
    </Layout>
  </div>
)

export default SoftwareAccess

